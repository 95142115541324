<script setup>

import {useStore} from "vuex";
import {computed} from "vue";
import {useRoute, useRouter} from "vue-router";

const store = useStore()
const router = useRouter()
const route = useRoute()
const userIsInReportsView = route.name === 'Reports View'
const reportUrl = computed(() => store.getters['reports/reportURL'])
const userWidth = computed(() => store.getters.getUserWidth)

const redirectUser = () => {
  closeCustomModal()
  router.push({name: 'Reports View'})
}

const openReportPdf = () => {
  closeCustomModal()
  window.open(reportUrl.value, "_blank");
}

const closeCustomModal = () => {
  store.dispatch('toastfy/changeCustomToastState', false)
}
</script>

<template>
    <div class="py-3 px-4 position-fixed bg-white rounded-2 custom-toast-position">
      <div class="w-100 d-flex justify-content-end">
        <i @click="closeCustomModal" class="mb-2 fs-5 bi bi-x-lg"></i>
      </div>

      <p class="text-center fw-bold main-message"><i class="me-2 bi bi-check-circle-fill"></i> Relatório Concluído!</p>
      <p v-if="userWidth >= 1024" class="text-center">
        O relatório que você gerou está pronto para ser baixado. Acesse clicando no botão abaixo.
      </p>

      <div class="d-flex justify-content-center">
        <button @click="userIsInReportsView ? openReportPdf() : redirectUser()" class="btn-primary btn">{{ userIsInReportsView ? 'Visualizar' : 'Acessar' }}</button>
      </div>
    </div>
</template>

<style scoped>
.bi-x-lg {
  cursor: pointer;
}

.main-message {
  color: var(--blue-0);
  font-size: calc(18px * var(--font-scale));
}

.custom-toast-position {
  transition: 0.5s;
  max-width: 420px;
  top: 15px;
  right: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}
</style>