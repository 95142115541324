import MaterialItemsService, {uploadItemsByZip} from "@/services/MaterialItemsService";

export default {
    state() {
        return {
            materialItem: {
                id: "",
                title: "",
                description: "",
                type: "",
                file: "",
                materialId: "",
                iframeCode: "",
                visible: "",
                quizzes: "",
                settings: "",
                isEditable: ""
            },
            materialItemData: {
                data: ""
            },
            materialItemsData: "",
            materialItemsAnswers: {},
            personalAttempts: {},
            success: "",
        }
    },

    getters: {
        getMaterialItem: (state) => state.materialItem,
        getMaterialItemTitle: (state) => state.materialItem.title,
        getMaterialItemDescription: (state) => state.materialItem.description,
        getMaterialItemType: (state) => state.materialItem.type,
        getMaterialItemFile: (state) => state.materialItem.file,
        getMaterialItemMaterialId: (state) => state.materialItem.materialId,
        getMaterialItemIframeCode: (state) => state.material.iframeCode,
        getMaterialItemVisible: (state) => state.material.visible,
        getMaterialItemData: (state) => state.materialItemData.data
    },

    mutations: {

        setErrorDataWhenUploadingZip(state, data) {
            !data && (state.errorDataWhenUploadingZip = null)
            state.errorDataWhenUploadingZip = data
        },

        setMaterialItemData(state, data) {
            state.materialItem.id = data.id;
            state.materialItem.title = data.title;
            state.materialItem.description = data.description;
            state.materialItem.type = data.type;
            state.materialItem.file = data.file;
            state.materialItem.materialId = data.materialId;
            state.materialItem.iframeCode = data.iframeCode;
            state.materialItem.visible = data.visible;
            state.materialItem.quizzes = data.quizzes;
            state.materialItem.settings = data.settings;
            state.materialItem.isEditable = data.is_editable;
        },
        resetMaterialItem(state) {
            state.material = {
                title: "",
                description: "",
                type: "",
                file: "",
                materialId: "",
                iframeCode: "",
                visible: ""
            };
        },
        setMaterialItemsData(state, data) {
            state.materialItemsData = data
        },
        createMaterialItem(state, data) {
            if (data instanceof Array) {
                state.materialItemsData.push(data[0])
                return
            }
            state.materialItemsData.push(data)
        },

        updateMaterialItem(state, data) {
            const index = state.materialItemsData.findIndex((m) => m.id == data.id)
            if (index === -1) return
            state.materialItemsData[index] = data
        },

        duplicateMaterialItem(state, data) {
            state.materialItemsData.splice(data.index, 0, data)
        },

        deleteMaterialItem(state, data) {
          const materialIndex = state.materialItemsData.findIndex((m) => m.id == data)
          if (materialIndex === -1) return
            state.materialItemsData.splice(materialIndex, 1)
        },

        setMaterialItemsAnswers(state, data) {
            state.materialItemsAnswers = data;
        },
        personalAttempts(state, data) {
            state.personalAttempts = data;
        },
        success: (state, message) => {
            state.success = message;
        },
    },

    actions: {
        async createMaterialItem({ commit, dispatch }, payload) {
            try {
                const response = await MaterialItemsService.createMaterialItem(payload.materialItem, payload.userToken);
                commit('createMaterialItem', response.data)

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar item:', error);

                throw error;
            } finally {
                dispatch('setLoadingState', { loadignState: false })
            }
        },

        async findMaterialItem({ commit }, payload) {
            try {
                const response = await MaterialItemsService.findMaterialItem(payload.materialItemId, payload.userToken);
                commit('setMaterialItemData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar item:', error);

                throw new Error('Não foi possível consultar item. Por favor, tente novamente.');
            }
        },

        async updateMaterialItem({ commit, dispatch }, payload) {
            try {
                const response = await MaterialItemsService.updateMaterialItem(payload.materialItem, payload.userToken);
                commit('updateMaterialItem', response.data)
                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar item:', error);

                throw error;
            }

            finally {
                dispatch('setLoadingState', { loadignState: false })
            }
        },

        async deleteMaterialItem({ commit, dispatch }, payload) {
            try {
                const response = await MaterialItemsService.deleteMaterialItem(payload.materialItemId, payload.userToken);
                commit('deleteMaterialItem', payload.materialItemId)
                return response.data;
            } catch (error) {
                console.error('Erro ao deletar item:', error);

                throw new Error('Não foi possível deletar a item. Por favor, tente novamente.');
            } finally {
                dispatch('setLoadingState', { loadignState: false })
            }
        },

        updateMaterialItemsPosition({commit}, payload) {
            commit('setMaterialItemsData', payload)
        },

        async getMaterialItems({ commit }, payload) {
            try {
                const response = await MaterialItemsService.getMaterialItems(payload.data, payload.userToken);

                commit('setMaterialItemsData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar items:', error);

                throw new Error('Não foi possível consultar as items. Por favor, tente novamente.');
            }
        },

        async duplicateMaterialItem({ commit, dispatch }, payload) {
            try {
                const response = await MaterialItemsService.duplicateMaterialItem(payload.materialItemId, payload.userToken);

                commit('duplicateMaterialItem', response.data)
                commit('setMaterialItemData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao duplicar item:', error);

                throw new Error('Não foi possível duplicar o item. Por favor, tente novamente.');
            } finally {
                dispatch('setLoadingState', { loadignState: false })
            }
        },

        async removeQuiz({ commit }, payload) {
            try {
                const response = await MaterialItemsService.removeQuiz(payload.data, payload.userToken);

                commit('resetMaterialItem');

                return response.data;
            } catch (error) {
                console.error('Erro ao remover questão:', error);

                throw error;
            }
        },

        async getPersonalAttempts({ commit }, payload) {
            try {
                const response = await MaterialItemsService.getAttempts(payload, payload.userToken);

                commit('personalAttempts', response.data);
            } catch (error) {
                console.error('Erro ao consultar tentativas:', error);

                throw error;
            }
        },

        async getAttempts({ commit }, payload) {
            try {
                const response = await MaterialItemsService.getAttempts(payload, payload.userToken);

                console.log(response.data);

                commit('setMaterialItemsAnswers', response.data);
            } catch (error) {
                console.error('Erro ao consultar tentativas:', error);

                throw error;
            }
        },

        async updateMaterialItemIndex({ commit }, payload) {
            try {
                const response = await MaterialItemsService.updateIndex(payload.data, payload.userToken);

                commit('success', 'Índice atualizado com sucesso!');

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar índice:', error);

                throw error;
            }
        },

        async uploadMaterialItemFile({ commit, dispatch }, payload) {
            try {
                const response = await MaterialItemsService.uploadMaterialItemFile(payload.data, payload.userToken);
                commit('createMaterialItem', response.data)
                return response.data;
            } catch (error) {
                console.error('Erro ao fazer upload dos arquivos:', error);

                throw error;
            } finally {
                dispatch('setLoadingState', { loadignState: false })
            }
        },

        async uploadItemsByZip({commit, dispatch}, payload) {
            try {
                dispatch('setLoadingState', {loadignState: true, loadingView: 'materialsView'}, {root: true});
                const response = await uploadItemsByZip(payload)
                return response
            } catch (error) {
                return error
            } finally {
                dispatch('setLoadingState', {loadignState: false}, {root: true});
            }
        },
    }
}
