<template>
  <div :style="{'--contrast-multiplier': contrastMultiplier}" class="contrast-filter"></div>
  <div :style="{'--font-scale': fontMultiplier, fontFamily: dyslexiaIsActive ? 'OpenDyslexic' : 'Poppins', '--image-filter': `hue-rotate(${imageFilter}deg)`}">
    <div
        v-if="loadingState"
        class="z-3 vw-100 vh-100 position-fixed d-flex align-items-center justify-content-center">
      <LoaderComponent/>
    </div>
    <div v-if="loadingView !== 'app'" class="d-flex">
      <SideBarComponent v-if="!shouldNotHaveSideBar.includes(route.name)"/>
      <AlertsInMobileItems v-if="route.name === 'My Disciplines' && userWidth < 1024"/>
      <div :class="!shouldNotHaveSideBar.includes(route.name) ? 'content-container' : 'full-size-container' ">
        <RouterView/>
        <AcessibilityItems/>
        <transition name="item_slide"> 
        <CustomToast v-if="customToastState" />
        </transition>
      </div>
      <ScrollToTopButton :scrollToTopState="scrollToTopState"/>
    </div>
  </div>

  <WarmUpNewFeatureModal v-if="activeModalName === 'WarmupNewFeature'"/>
</template>

<script>
import {mapGetters, useStore} from 'vuex';
import 'vue3-toastify/dist/index.css';
import AcessibilityItems from "@/components/items/AcessibilityItems.vue";
import ScrollToTopButton from "@/components/buttons/ScrollToTopButton.vue";
import {useRoute, useRouter} from "vue-router";
import SideBarComponent from "@/components/sidebars/SidebarComponent.vue";
import LoaderComponent from "@/components/loaders/LoaderComponent.vue";
import store from "@/store";
import {computed, nextTick} from "vue";
import AlertsInMobileItems from "@/components/items/AlertsInMobileItems.vue";
import {setApplicationColors} from "./utils/helpers";
import WarmUpNewFeatureModal from "./components/modals/WarmUpNewFeatureModal.vue";
import CustomToast from "./components/cards/CustomToast.vue";

export default {

  data() {
    return {
      store: useStore(),
      showAlertListState: false,
      userWidth: window.innerWidth,
      token: computed(() => store.getters.getToken),
      logOutExpiratioTime: 14400,
      router: useRouter(),
      route: useRoute(),
      shouldNotInteractWidthLogOutTimer: ['Login', 'Forgot Password', 'Error', 'Not Found'],
      modalExceptionActions: ['InsertMaterialItem'],
      shouldNotPing: ['Login', 'Forgot Password'],
      shouldNotHaveSideBar: ['Login', 'Forgot Password', 'Error', 'Not Found'],
      scrollViews: ['Student DashBoard', 'Disciplines', 'Questionnaire', 'My personalized disciplines', 'Create Quiz', 'Discipline Material', 'My Disciplines', 'Questionnaire Feedback', 'Quizzes', 'Update Quiz']
    }
  },

  components: {
    WarmUpNewFeatureModal,
    CustomToast,
    AlertsInMobileItems,
    AcessibilityItems,
    LoaderComponent,
    SideBarComponent,
    ScrollToTopButton,
  },

  computed: {
    ...mapGetters({
      dyslexiaIsActive: 'acessibility/dyslexiaIsActive',
      contrastMultiplier: 'acessibility/contrastMultiplier',
      fontMultiplier: 'acessibility/fontMultiplier',
      colorBlindnessGetter: 'acessibility/colorBlindnessGetter',
      colorBlindnessParams: 'acessibility/colorBlindnessParams',
      imageFilter: 'acessibility/imageFilter',
      consumerSettings: 'getConsumerSettings',
      customToastState: 'toastfy/customToastState',
      modalState: 'getModalState',
      modalWrapperDiv: "getModalWrapperDiv",
      formModalState: 'getFormModalState',
      activeModalName: 'getModalName',
      userEnteredSomethingInModal: 'getUserEnteredSomething',
      selectState: 'getSelectState',
      selectWrapperDiv: 'getSelectWrapper'
    }),
    ...mapGetters(["isLoading", 'loadingState', 'loadingView']),
    ...mapGetters('scrollToTop', ['scrollToTopState']),
  },

  mounted() {
    this.initializeLocalFunctions()
    this.initializeStoresDispatch()
    this.initializeScriptsAndJSFunctions()
    this.initializeListeners()
  },

  watch: {
    'route.name'(newValue, oldValue) {
      if (oldValue === 'Login' && newValue !== oldValue) {
        this.resetLogOutTimer()
        this.startTimeCounter()
      }
    },

    colorBlindnessGetter: {
      handler(newValue) {
        this.setSystemColors(newValue)
      },
      deep: true
    }

  },

  beforeUnmount() {
    this.stopListeners()
  },

  methods: {
    resetStatesInStores() {
      this.store.dispatch('setWarmUpData')
    },

    setSystemColors(colorBlindnessState) {
      if (colorBlindnessState.state) {
        setApplicationColors(this.colorBlindnessParams[colorBlindnessState.index])
        this.store.dispatch('editConsumerColors', this.colorBlindnessParams[colorBlindnessState.index])
        return
      }
      setApplicationColors({ primary_color: this.consumerSettings.original_primary_color, secondary_color: this.consumerSettings.original_secondary_color })
      this.store.dispatch('editConsumerColors',{primary_color: this.consumerSettings.original_primary_color, secondary_color: this.consumerSettings.original_secondary_color})
      this.store.dispatch('acessibility/resetContrastMultiplier')
    },

    startTimeCounter() {
      const invervalId = setInterval(() => {

        if (this.logOutExpiratioTime <= 0 && !this.shouldNotInteractWidthLogOutTimer.includes(this.route.name)) {
          this.router.push({name: 'Login'});
          localStorage.removeItem('authToken');
          clearInterval(invervalId)
        }

        if (this.logOutExpiratioTime <= 0) {
          clearInterval(invervalId)
        }

        this.logOutExpiratioTime--
      }, 1000)
    },

    checkUserInputsInModal() {
      if (this.modalState) {
        this.store.dispatch('setUserEnteredSomething')
      }
    },

    handleClickFunctions(event) {
      this.checkSelectState(event)
      this.checkModalState(event)
      this.resetLogOutTimer()
    },

    handleModalExceptions() {
      switch (this.modalWrapperDiv) {
        case '.quizzes-list-wrapper':
          this.store.dispatch('setModalData', { modalName: 'InsertMaterialItem', formModal: true })
          break;
        case '.modal-content':

          if (this.formModalState && this.userEnteredSomethingInModal) {
            const userConfirmed = window.confirm("Tem certeza de que deseja sair? Todas as informações não salvas serão perdidas!")
            if (!userConfirmed) return
          }

          this.store.dispatch('clearUserEnteredSomething')
          this.store.dispatch('setModalData', {
            modalName: 'CreateMaterialModal',
          });
          break;
      }
    },

    checkSelectState(event) {
      if (this.selectState) {
        const wrapper = document.querySelector(this.selectWrapperDiv)

        if (wrapper && wrapper.contains(event.target)) return

        this.store.dispatch('clearSelectData')
      }
    },

    checkModalState(event) {
      if (this.modalState) {
        const wrapper = document.querySelector(this.modalWrapperDiv)

        if (wrapper && wrapper.contains(event.target)) return

        if(this.modalExceptionActions.includes(this.activeModalName)) return this.handleModalExceptions()

        if (this.formModalState && this.userEnteredSomethingInModal) {
          const userConfirmed = window.confirm("Tem certeza de que deseja sair? Todas as informações não salvas serão perdidas!")
          if (!userConfirmed) return
        }

        this.store.dispatch('clearModalData')
      }
    },

    resetLogOutTimer() {
      if (!this.shouldNotInteractWidthLogOutTimer.includes(this.route.name) && this.logOutExpiratioTime <= 300) {
        this.logOutExpiratioTime = 14400
      }
    },

    handleResize() {
      this.$store.dispatch('setUserWidth', window.innerWidth)
    },

    userPing() {
      setInterval(() => {
        if (!this.shouldNotPing.includes(this.route.name) && this.token) {
          this.$store.dispatch('pingUser', {userToken: this.token})
              .catch(() => {
                console.log("Realize Login Novamente!")
                this.router.push({name: 'Login'});
              });
        }
      }, 60000);
    },

    handleScroll() {
      const shouldButtonAppear = this.scrollViews.includes(this.route.name)

      if (window.scrollY > 100) {
        if (shouldButtonAppear) {
          this.$store.dispatch('scrollToTop/setScrollToTop', {
            scrollToTopState: true
          })
        }
      } else {
        this.$store.dispatch('scrollToTop/setScrollToTop', {
          scrollToTopState: false
        })
      }
    },

    async initializeStoresDispatch() {
      await this.store.dispatch('initializeReverb', this.token)
      this.store.dispatch('clearModalData')
      this.store.dispatch('clearSelectData')
      this.store.dispatch('reports/resetReportsData')
      this.store.dispatch('toastfy/changeCustomToastState', false)
      this.store.dispatch('alerts/setShowAlertModalData', { state: false,  alertToShow: null})
      this.store.dispatch('scrollToTop/setScrollToTop', { scrollToTopState: false })
      this.store.dispatch('alerts/setShowAlertModalData', { state: false,  alertToShow: null})
      this.store.dispatch('scrollToTop/setScrollToTop', { scrollToTopState: false })
    },

    initializeListeners() {
      window.addEventListener('change', this.checkUserInputsInModal)
      window.addEventListener("input", this.checkUserInputsInModal);
      window.addEventListener('scroll', this.handleScroll);
      window.addEventListener('resize', this.handleResize)
      window.addEventListener("mousemove", this.resetLogOutTimer);
      window.addEventListener("keypress", this.resetLogOutTimer);
      window.addEventListener("click", this.handleClickFunctions);
      window.addEventListener("touchstart", this.resetLogOutTimer);
      window.addEventListener('beforeunload', this.resetStatesInStores)
    },

    initializeLocalFunctions() {
      this.userPing()
      this.setSystemColors(this.colorBlindnessGetter)
      this.handleResize()
      if (!this.shouldNotInteractWidthLogOutTimer.includes(this.route.name)) {
        this.startTimeCounter()
      }
    },

    initializeScriptsAndJSFunctions() {
      const script = document.createElement('script');
      script.src = 'https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.async = true;
      document.head.appendChild(script);
      window.googleTranslateElementInit = () => {
        new google.translate.TranslateElement({
          pageLanguage: "pt",
          includedLanguages: 'en,es',
          layout: google?.translate?.TranslateElement?.InlineLayout.SIMPLE
        }, 'google_translate_element');
      };
      document.documentElement.style.fontSize = `${16 * this.fontMultiplier}px`;
    },

    stopListeners() {
      window.removeEventListener('change', this.checkUserInputsInModal)
      window.removeEventListener('input', this.checkUserInputsInModal)
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('resize', this.handleResize)
      window.removeEventListener('scroll', this.handleScroll);
      window.removeEventListener('mousemove', this.resetLogOutTimer);
      window.removeEventListener('keypress', this.resetLogOutTimer);
      window.addEventListener("click", this.handleClickFunctions);
      window.removeEventListener('touchstart', this.resetLogOutTimer);
      window.removeEventListener('beforeunload', this.resetStatesInStores)
    },
  }
};
</script>

<style scoped>

.contrast-filter {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: saturate(var(--contrast-multiplier));
  z-index: 9999;
  pointer-events: none;
  transform: translateZ(0);
}

.full-size-container {
  width: 100%;
  height: 100%;
}

.content-container {
  width: 0px;
  flex-grow: 1;
  padding: 4% 2%;
}

@media (max-width: 1280px) {
  .content-container {
    padding-left: 2%;
  }

  .content-container {
    padding-top: 15%;
  }
}

</style>