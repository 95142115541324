const state = {
    dyslexiaIsActive: false,
    fontMultiplier: 1,
    contrastMultiplier: 1,
    colorsParamsDataParams: [
        { primary_color: '#23761a', secondary_color: '#1A5276' }, //vermelho
        { primary_color: '#1A5276', secondary_color: '#ff0026' }, // Verde
        { primary_color: '#ff0026', secondary_color: '#aa00ff' }, // Azul
        { primary_color: '#23761a', secondary_color: '#1A5276' },
    ],
    colorBlindnessImagesFilter: 0,
    colorBlindnessIndex: -1,
    colorBlindnessState: false,
    newLanguageState: false
}

const mutations = {
    setNewLanguageState: (state) => {
        state.newLanguageState = !state.newLanguageState
    },

    setColorBlindnessState(state) {
        state.colorBlindnessIndex = state.colorBlindnessIndex + 1
        if (!state.colorsParamsDataParams[state.colorBlindnessIndex]) {
            state.colorBlindnessState = false
            state.contrastMultiplier = 1
            state.colorBlindnessIndex = -1
            state.colorBlindnessImagesFilter = 0
            return
        }

        switch (state.colorBlindnessIndex) {
            case 0:
                state.colorBlindnessImagesFilter = 90
                break;
            case 1:
                state.colorBlindnessImagesFilter = 200
                break
            case 2:
                state.colorBlindnessImagesFilter = 360
                break;
        }

        state.colorBlindnessIndex === 3 ? state.contrastMultiplier = 0 : state.contrastMultiplier = 1
        state.colorBlindnessState = true
    },

    setDyslexiaState(state, payload) {
        state.dyslexiaIsActive = payload.isActive
    },


    incrementFontMultiplier(state) {
        state.fontMultiplier = parseFloat((state.fontMultiplier + 0.1).toFixed(1));
    },

    decrementFontMultiplier(state) {
        state.fontMultiplier = parseFloat((state.fontMultiplier - 0.1).toFixed(1));
    },

    resizeFontMultipler(state) {
        state.fontMultiplier = 1.6
    },

    resetContrastMultiplier(state) {
        state.contrastMultiplier = 1
    },

    incrementContrastMultiplier(state) {
        state.contrastMultiplier = parseInt((state.contrastMultiplier + 4))
    },

    decrementContrastMultiplier(state) {
        state.contrastMultiplier = parseInt((state.contrastMultiplier - 4))
    },
}

const actions = {
    setNewLanguageState({commit}) {
        commit('setNewLanguageState')
    },

    resetContrastMultiplier({commit}) {
        commit('resetContrastMultiplier')
    },

    setColorBlindnessState({commit}) {
        commit('setColorBlindnessState')
    },

    resizeFontMultipler({commit}) {
        commit('resizeFontMultipler')
    },

    setDyslexiaState({commit}, payload) {
        commit('setDyslexiaState', payload)
    },

    setIncrementContrastMultiplier({commit, dispatch, state}) {
        if (state.contrastMultiplier < 13) {
            if (state.colorBlindnessIndex === 3) {
                dispatch(
                    'toastfy/activeErrorNotification',
                    { text: "Insira um filtro de cor válido!" },
                    { root: true }
                );
                return
            }
            commit('incrementContrastMultiplier')
            return
        }

        dispatch(
            'toastfy/activeErrorNotification',
            { text: "Auto contraste máximo atingido" },
            { root: true }
        );
    },

    setDecrementContrastMultiplier({commit, dispatch, state}) {
        if (state.contrastMultiplier > 1) {
            if (state.colorBlindnessIndex === 3) {
                dispatch(
                    'toastfy/activeErrorNotification',
                    { text: "Insira um filtro de cor válido!" },
                    { root: true }
                );
                return
            }
            commit('decrementContrastMultiplier')
            return
        }

        dispatch(
            'toastfy/activeErrorNotification',
            { text: "Auto contraste mínimo atingido" },
            { root: true }
        );
    },

    setIncrementMultiplier({commit, dispatch, state}, payload) {

        if ((state.fontMultiplier < 2 && payload.userWidth >= 1024) || (state.fontMultiplier < 1.6 && payload.userWidth < 1024)) {
            commit('incrementFontMultiplier')
            return
        }
        dispatch(
            'toastfy/activeErrorNotification',
            { text: "Tamanho máximo atingido" },
            { root: true }
        );
    },

    setDecrementFontMultiplier({commit, dispatch, state}) {
        if (state.fontMultiplier > 1) {
            commit('decrementFontMultiplier')
            return
        }
        dispatch(
            'toastfy/activeErrorNotification',
            { text: "Tamanho mínimo atingido" },
            { root: true }
        );
    }
}

const getters = {
    newLanguageState: (state) => state.newLanguageState,
    imageFilter: (state) => state.colorBlindnessImagesFilter,
    colorBlindnessState: (state) => state.colorBlindnessState,
    colorBlindnessGetter: (state) => ({ state: state.colorBlindnessState, index: state.colorBlindnessIndex }),
    colorBlindnessParams: (state) => state.colorsParamsDataParams,
    dyslexiaIsActive: (state) => state.dyslexiaIsActive,
    fontMultiplier: (state) => state.fontMultiplier,
    contrastMultiplier: (state) => state.contrastMultiplier
}

export default {
    namespaced: true,
    actions,
    getters,
    mutations,
    state
}