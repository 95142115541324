import {createRouter, createWebHistory} from "vue-router";
import {useStore} from 'vuex';
import store from '@/store';
import axios from 'axios';
import tinycolor from 'tinycolor2';
import {setApplicationColors} from "../utils/helpers";

const routes = [
    {
        path: "/",
        redirect: "/login",
        component: () => import("@/views/LoginView.vue"),
        meta: {
            title: "Login",
        },
    },

    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/LoginView.vue"),
        meta: {
            title: "Login",
        },
    },

    {
        path: "/forgot-password",
        name: "Forgot Password",
        component: () => import("@/views/ForgotPasswordView.vue"),
        meta: {
            title: "Recuperação de senha",
        },
    },

    {
        path: "/validate",
        name: "Validate Session",
        component: () => import("@/views/ValidateSessionView.vue"),
        meta: {
            title: "Validação",
        }
    },

    {
        path: "/not-found",
        name: "Not Found",
        component: () => import("@/views/NotFoundView.vue"),
        meta: {
            title: "Não encontrado",
        }
    },

    {
        path: "/error",
        name: "Error",
        component: () => import("@/views/NoLicenseView.vue"),
        meta: {
            title: "Erro ao acessar",
        }
    },

    {
        path: "/users",
        name: "Users",
        component: () => import("@/views/UsersListView.vue"),
        beforeEnter: async (to, from, next) => {
            const token = store.getters.getToken
            const payload = {
                data: {
                    page: 1,
                    perPage: 30,
                    role: 'users'
                },
                userToken: token
            }

            try {
                await store.dispatch('getUsers', payload)
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }
        },
        meta: {
            title: "Usuários",
            requiresAuth: true,
        },
    },

    {
        path: "/users/create",
        name: "Create Users",
        component: () => import("@/views/CreateUserView.vue"),
        meta: {
            title: "Adicionar Usuário",
            requiresAuth: true,
        },
    },

    {
        path: "/users/:id/change-password",
        name: "Change Password",
        component: () => import("@/views/ChangePasswordView.vue"),
        meta: {
            title: "Alterar Senha",
            requiresAuth: true,
        },
    },

    {
        path: "/companies/create",
        name: "Create Companies",
        component: () => import("@/views/CreateCompanyView.vue"),
        meta: {
            title: "Adicionar Empresa",
            requiresAuth: true,
        },
    },

    {
        path: "/companies",
        name: "Companies",
        component: () => import("@/views/CompaniesListView.vue"),
        beforeEnter: async (to, from, next) => {
            const userToken = store.getters.getToken
            const payload = {
                data: {
                    status: 'active',
                },
                userToken: userToken
            }

            try {
                await store.dispatch('getCompanies', payload)
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }

        },
        meta: {
            title: "Empresas",
            requiresAuth: true,
        },
    },

    {
        path: "/companies/:id/update",
        name: "Update Company",
        component: () => import("@/views/UpdateCompanyView.vue"),
        beforeEnter: async (to, from, next) => {
            const userToken = store.getters.getToken
            const payload = {
                userToken: userToken,
                companyId: to.params.id
            }

            try {
                await store.dispatch('findCompany', payload)
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }
        },
        meta: {
            title: "Atualizar Empresa",
            requiresAuth: true
        }
    },

    {
        path: "/consumers/settings",
        name: "Update Consumer Settings",
        component: () => import("@/views/ConsumerSettingsView.vue"),
        meta: {
            title: "Atualizar dados do consumidor",
            requiresAuth: true
        }
    },

    {
        path: "/home",
        name: "Home",
        component: () => import("@/views/AdminHomePageView.vue"),
        beforeEnter: async (to, from, next) => {
            const token = store.getters.getToken

            try {
                await store.dispatch('getMetrics', {userToken: token})
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }
        },
        meta: {
            title: "Início",
            requiresAuth: true
        }
    },

    /*
    {
      path: "/products",
      name: "Products",
      component: () => import("@/views/ProductsView.vue"),
      meta: {
        title: "Produtos",
        requiresAuth: true,
      },
    },
    */

    {
        path: "/integrations",
        name: "Integrations",
        component: () => import("@/views/IntegrationsView.vue"),
        beforeEnter: async (to, from, next) => {
            const token = store.getters.getToken
            try {
                await store.dispatch('getLtiConsumers', {userToken: token, data: {search: ''}})
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }

        },
        meta: {
            title: "Integrações",
            requiresAuth: true
        }
    },

    {
        path: "/my-disciplines",
        name: "My personalized disciplines",
        component: () => import("@/views/ConsumerDisciplinesView.vue"),
        beforeEnter: async (to, from, next) => {
            const userToken = store.getters.getToken
            const payload = {
                data: {
                    perPage: 30,
                    page: 1,
                    status: 'pending'
                },
                userToken: userToken
            }

            try {
                await store.dispatch('getConsumerDisciplines', payload)
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }
        },
        meta: {
            title: "Minhas Disciplinas",
            requiresAuth: true
        }
    },

  {
    path: "/my/disciplines",
    name: "My Disciplines",
    component: () => import("@/views/MyDisciplinesView.vue"),
    beforeEnter: async (to, from, next) => {
      const token = store.getters.getToken
      const userId = store.getters.getId
        const userType = store.getters.getType
        const companyData = store.getters.getCompany

      const payload = {
        userToken: token,
      }

        const warningsPayload = {
            filter: {company_id: companyData.id, status: 'active', perPage: 30},
            token: token
        }

        const StudentPayload = {
            userToken: token,
            userId: userId,
            type: 'Specific User'
        }

        const disciplinesPayload = {
            data: {
                perPage: 30
            },
            userToken: token
        }


        await store.dispatch('getDisciplines', disciplinesPayload)
        await store.dispatch('alerts/GetAlerts', warningsPayload)

        if (userType !== 'student') {
            try {
                await store.dispatch('getTutorMetrics', payload)
                next()
            } catch {
                next(from)
            }
            return
        }

        try {
            await store.dispatch('getConsumerBadges', StudentPayload)
            await store.dispatch('getSessionData', StudentPayload)
            next()
        } catch {
            next(from)
        }
    },
    meta: {
      title: "Minhas Disciplinas",
      requiresAuth: true,
    },
  },

    {
        path: "/disciplines",
        name: "Disciplines",
        component: () => import("@/views/DisciplinesView.vue"),
        beforeEnter: async (to, from, next) => {
            const userToken = store.getters.getToken
            const disciplinesPayload = {
                userToken: userToken,
                data: {
                    page: 1,
                    perPage: 30
                }
            }

            try {
                await store.dispatch('getSuppliers', userToken)
                await store.dispatch('getDisciplineTags', {userToken: userToken})
                await store.dispatch('getDisciplines', disciplinesPayload)
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }
        },
        meta: {
            title: "Disciplinas",
            requiresAuth: true,
        },
    },

    {
        path: "/discipline/:id/materials",
        name: "Discipline Material",
        component: () => import("@/views/MaterialsDisciplineView.vue"),
        beforeEnter: async (to, from, next) => {
            const userToken = store.getters.getToken
            const materialsPayload = {
                data: {
                    disciplineId: to.params.id
                },
                userToken: userToken
            }

            const disciplinePayload = {
                disciplineId: to.params.id,
                userToken: userToken
            }

            try {
                await store.dispatch('getMaterials', materialsPayload)
                await store.dispatch('findDiscipline', disciplinePayload)
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }
        },
        meta: {
            title: "Materiais",
            requiresAuth: true,
        },
    },

    {
        path: "/discipline/:id/class",
        name: "Discipline Class",
        component: () => import("@/views/ClassDisciplineView.vue"),
        beforeEnter: async (to, from, next) => {
            const userToken = store.getters.getToken

            const payload = {
                data: {
                    disciplineId: to.params.id,
                    page: 1,
                    perPage: 30
                },
                userToken: userToken
            }

            try {
                await store.dispatch('getDisciplineInscriptions', payload)
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }
        },
        meta: {
            title: "Turma",
            requiresAuth: true,
        },
    },

    {
        path: "/discipline/:id/grades",
        name: "Discipline Grades",
        component: () => import("@/views/GradesDisciplineView.vue"),
        beforeEnter: async (to, from, next) => {
            const userToken = store.getters.getToken
            const payload = {
                data: {
                    page: 1,
                    perPage: 30
                },
                userToken: userToken
            }

            try {
                await store.dispatch('getGrades', payload)
                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }

        },
        meta: {
            title: "Notas",
            requiresAuth: true,
        },
    },

    {
        path: "/discipline/:id/materials/:materialItemId/quiz",
        name: "Questionnaire",
        component: () => import("@/views/QuestionnaireView.vue"),
        beforeEnter: async (to, from, next) => {
            const userToken = store.getters.getToken
            const userType = store.getters.getType
            const materialItemPayload = {
                materialItemId: to.params.materialItemId,
                userToken: userToken
            }

            const personalAttempts = {
                ...materialItemPayload,
                userId: store.getters.getUserId
            }


            try {
                await store.dispatch('getPersonalAttempts', personalAttempts)
                await store.dispatch('findMaterialItem', materialItemPayload)

                if (userType !== 'student') {
                    await store.dispatch('getAttempts', materialItemPayload);
                }

                next()
            } catch {
                next(from)
                store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
            }
        },
        meta: {
            title: "Questionário",
            requiresAuth: true,
        },
    },

    {
        path: "/discipline/:id/materials/:materialItemId/quiz/attempt/:attemptId",
        name: "Questionnaire Attempt",
        component: () => import("@/views/QuestionnaireAttemptView.vue"),
        meta: {
            title: "Questionário",
            requiresAuth: true,
            internalRoute: true
        },
        beforeEnter: async (to, from, next) => {
            const token = store.getters.getToken
            const materialPayload = {
                materialItemId: to.params.materialItemId,
                userToken: token
            }

            const quizzesPayload = {
                data: {
                    materialItemId: to.params.materialItemId
                },
                userToken: token
            }

            const attemptPayload = {
                data: {
                    attemptId: to.params.attemptId,
                    materialItemId: to.params.materialItemId
                },
                userToken: token
            }

            if (to.meta.internalRoute) {
                try {
                    await store.dispatch('findMaterialItem', materialPayload)
                    await store.dispatch('getQuizzes', quizzesPayload)
                    await store.dispatch('findAttempt', attemptPayload)
                    next()
                } catch {
                    next(from)
                    store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
                }
            } else {
                next({name: "Questionnaire"});
            }
        }
    },

    {
        path: "/discipline/:id/materials/:materialItemId/quiz/attempt/:attemptId/feedback",
        name: "Questionnaire Feedback",
        component: () => import("@/views/QuestionnaireFeedbackView.vue"),
        meta: {
            title: "Feedback do Questionário",
            requiresAuth: true,
            internalRoute: true
        },
        beforeEnter: async (to, from, next) => {
            if (from.name === null) {
                next({name: "Questionnaire Attempt"});
            } else if (to.meta.internalRoute) {
                const token = store.getters.getToken

                const quizzesPayload = {
                    data: {
                        materialItemId: to.params.materialItemId,
                        allowFeedback: true
                    },
                    userToken: token
                }

                const materialPayload = {
                    userToken: token,
                    materialItemId: to.params.materialItemId
                }

                const attemptPayload = {
                    data: {
                        attemptId: to.params.attemptId
                    },
                    userToken: token
                }

                try {
                    await store.dispatch('getQuizzes', quizzesPayload)
                    await store.dispatch('findMaterialItem', materialPayload)
                    await store.dispatch('findAttempt', attemptPayload)
                    next();
                } catch {
                    next(from)
                    store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
                }
            } else {
                next({name: "Questionnaire Attempt"});
            }
        }
    },

    {
        path: "/discipline/:id/materials/:materialItemId/quiz/answers",
        name: "Questionnaire Answers",
        component: () => import("@/views/QuestionnaireAnswersView.vue"),
        meta: {
            title: "Tentativas do Questionário",
            requiresAuth: true,
            internalRoute: true
        },
        beforeEnter: async (to, from, next) => {
            if (to.meta.internalRoute) {
                const token = store.getters.getToken

                const payload = {
                    materialItemId: to.params.materialItemId,
                    userToken: token,
                    role: 'student'
                }

                try {
                    await store.dispatch('getAttempts', payload)
                    await store.dispatch('findMaterialItem', payload)
                    next();
                } catch {
                    next(from)
                    store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
                }
            } else {
                next({name: "Questionnaire"});
            }
        }
    },

  {
    path: "/quizzes/:id",
    name: "Quizzes",
    component: () => import("@/views/QuizzesView.vue"),
      beforeEnter: async (to, from, next) => {
          const token = store.getters.getToken
          const findDisciplinePayload = {
              disciplineId: to.params.id,
              userToken: token
          }

          const quizzesPayload = {
              data: {
                  disciplineId: to.params.id,
                  page: 1,
                  perPage: 30
              },
              userToken: token
          }

          const disciplinePayload = {
              userToken: token,
              data: {
                  page: 1,
                  perPage: 30
              }
          }

          const topicsPayload = {
              userToken: token,
              data: {
                  disciplineId: to.params.id
              }
          }
          try {
              await store.dispatch('findDiscipline', findDisciplinePayload)
              await store.dispatch('getQuizzes', quizzesPayload)
              await store.dispatch('getDisciplines', disciplinePayload)
              await store.dispatch('getDisciplineTopics', topicsPayload)
              next()
          } catch {
              next(from)
              store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
          }
      },
    meta: {
      title: "Banco de Questões",
      requiresAuth: true
    }
  },
  {
    path: "/quizzes/:id/create",
    name: "Create Quiz",
    component: () => import("@/views/CreateQuizView.vue"),
    meta: {
      title: "Criar Questão",
      requiresAuth: true
    }
  },
  {
    path: "/quizzes/:id/update",
    name: "Update Quiz",
      beforeEnter: async (to, from, next) => {
          const token = store.getters.getToken
          const payload = {
              quizId: to.query.quizId,
              userToken: token
          }

          try {
              await store.dispatch('findQuiz', payload)

              next()
          } catch {
              next(from)
              store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
          }
      },
    component: () => import("@/views/UpdateQuizView.vue"),
    meta: {
      title: "Atualizar Questão",
      requiresAuth: true
    }
  },
  {
    path: "/calendar",
    name: "Calendar View",
      beforeEnter: async (to, from ,next) => {
          const userToken = store.getters.getToken
          try {
              await store.dispatch('events/getEventsData', { userToken: userToken })
              next()
          }  catch {
              next(from)
              store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
          }
      },
    component: () => import("@/views/CalendarView.vue"),
    meta: {
      title: "Calendário",
      requiresAuth: true
    }
  },
  {
    path: "/notes",
    name: "Warning View",
      beforeEnter: async (to, from, next) => {
          const token = store.getters.getToken
          const companyId = store.getters.getCompany
          console.log("Company: ", companyId.id)
          const payload = {
              filter: {
                  company_id: companyId.id
              },
              token: token
          }

          try {
              await store.dispatch('alerts/GetAlerts', payload)
              next()
          } catch {
              next(from)
              store.dispatch('toastfy/activeErrorNotification', {text: 'Ocorreu um erro ao acessar a página, tente novamente!'})
          }
      },
    component: () => import("@/views/WarningView.vue"),
    meta: {
      title: "Avisos",
      requiresAuth: true
    }
  },
  {
    path: "/reports",
    name: "Reports View",
    component: () => import('@/views/ReportsView.vue'),
    meta: {
      title: 'Relatórios',
      requiresAuth: true
    }
  },
    {
        path: '/dashboard',
        name: 'Student DashBoard',
        component: () => import('@/views/StudentDashBoardView.vue'),
        beforeEnter: async(from, to, next) => {
            const token = store.getters.getToken
            const userId = store.getters.getId

            const payload = {
                userToken: token,
                userId: userId,
                type: 'Specific User'
            }

            const requestStudentsStatusPayload = {
                userToken: token,
                type: 'Students Rank',
            }

            try {
                store.dispatch('getConsumerBadges', payload)
                store.dispatch('getSessionData', requestStudentsStatusPayload)
                next()
            } catch {
                next(from)
            }
        },
        meta: {
            title: 'DashBoard',
            requiresAuth: true
        }
    },

    {
        path: "/tutor/dashboard",
        name: "Tutor DashBoard",
        beforeEnter: async (to, from ,next) => {
            const token = store.getters.getToken
            const tutorId = store.getters.getId
            const studentsListPayload = {
                userToken: token,
                data: {
                    tutorId: tutorId,
                    role: 'student',
                    page: 1,
                    perPage: 5,
                }
            }

            const studentsDataPayload = {
                type: 'Students Data For Tutors',
                userToken: token,
                tutorId: tutorId,
                perPage: 20,
                page: 1
            }

            try {
                store.dispatch('getUsers', studentsListPayload)
                store.dispatch('getSessionData', studentsDataPayload)
                next()
            } catch {
                next(false)
            }

        },
        component: () => import("@/views/TutorDashBoardView.vue"),
        meta: {
            title: 'DashBoard',
            requiresAuth: true
        }
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'Error',
        component: () => import('@/views/NotFoundView.vue')
    }
];

const getSubdomain = () => {
    const host = window.location.host;

    return host.split('.')[0];
};

const fetchConsumerSettings = async (subdomain) => {
  try {
    const response = await axios.get(process.env.VUE_APP_APPLICATION_SECURITY + subdomain + '.' + process.env.VUE_APP_ARANDU_API_URL + '/settings');
    return { data: response.data, status: response.status };
  } catch (error) {
    console.error('Erro ao obter as configurações do consumidor:', error);
    return { data: null, status: error.response ? error.response.status : 500 };
  }
};

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach(async (to, from, next) => {
  const store = useStore();
  const daltonismState = store.getters['acessibility/colorBlindnessState']
  store.dispatch('setLoadingState', { loadignState: true, loadingView: 'app'  })
  document.title = to.meta.title || "Guairacá Edtech";

    const subdomain = getSubdomain();

    const {data: settings, status} = await fetchConsumerSettings(subdomain);

  if (settings !== null && !daltonismState) {
    store.dispatch('setConsumerSettings', settings);
    await setApplicationColors(settings.settings);
  }

    if (status === 404 || !settings) {
        next('/error');
    } else {
        next();
    }
});

router.afterEach(() => {
    setTimeout(() => {
        store.dispatch('setLoadingState', {loadignState: false})
    }, 500);
});

export default router;