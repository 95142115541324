import MaterialsApiService from "@/services/api/MaterialsApiService";

export const getEvents = async (payload) => {
    let url = '/events'

    if (payload.search) {
        url += `?search=${payload.search}`
    }

    try {
        const response = await MaterialsApiService.get(url, {
            headers: {
                Authorization: `Bearer ${payload.userToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response.data
    } catch {
        throw new Error("Erro ao requisitar Eventos")
    }
}

export const createEvent = async (payload) => {
    try {
        const response = await MaterialsApiService.post('/events', payload.eventData, {
            headers: {
                Authorization: `Bearer ${payload.userToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response.data
    } catch {
        throw new Error("Erro ao criar Evento")
    }
}

export const editEvent = async (payload) => {
    try {
        const response =  await MaterialsApiService.post(`/events/${payload.eventData.id}/update`, payload.eventData, {
            headers: {
                Authorization: `Bearer ${payload.userToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })
        return response.data
    } catch {
        throw new Error("Erro ao editar Evento")
    }
}

export const deleteEvent = async (payload) => {
    try {
        const response = await MaterialsApiService.delete(`/events/${payload.eventId}`, {
            headers: {
                Authorization: `Bearer ${payload.userToken}`,
                'Content-Type': 'multipart/form-data'
            }
        })

        return response.data
    } catch {
        throw new Error("Erro ao deletar Evento")
    }
}

export default {
    getEvents,
    createEvent,
    deleteEvent,
    editEvent
}