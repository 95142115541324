<template>
  <Transition name="fade">
    <button v-show="scrollToTopState" class="scroll-to-top-button rounded-3 text-white position-fixed border-0" @click="scrollToTop">
      <i class="bi bi-chevron-double-up"></i>
    </button>
  </Transition>
</template>

<script>
export default {
  name: 'ScrollToTopButton',
  props: {
    showButton: {
      type: Boolean,
      default: false
    },
    scrollToTopState: {
      type: Boolean
    }
  },

  methods: {
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style scoped>
.scroll-to-top-button {
  right: 10px;
  bottom: 2rem;
  width: 37px;
  height: 37px;
  font-size: 20px;
  background-color: var(--blue-5);
  margin-bottom: 20px;


  &:hover {
    background-color: var(--blue-0);
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease; /* Controla a duração e suavidade */
}

/* Classe inicial do fade-in (opacidade 0) */
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}

/* Classe final do fade-out (opacidade 1) */
.fade-enter-to, .fade-leave-from {
  opacity: 1;
}
</style>