import { createApp } from "vue";
import moment from 'moment';
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap';
import "bootstrap-vue/dist/bootstrap-vue.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/styles.css";
import { Toasted } from "vue-toasted";
import { tooltip } from "../public/js/tooltip";
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueTheMask from 'vue-the-mask';
import { MaskInput } from 'vue-3-mask';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
const app = createApp(App);
app.config.globalProperties.$filters = {
    formatDate(value) {
        if (value) {
            return moment(String(value)).format('DD-MM-YYYY hh:mm:ss');
        }
    }
};
app.component('MaskInput', MaskInput);
app.use(router)
    .use(store)
    .use(CKEditor)
    .use(VCalendar, {})
    .use(Toasted, {
    iconPack: "fontawesome",
})
    .use(VueTheMask)
    .mount("#app");
app.directive('tooltip', tooltip);
// store.dispatch("login/checkAuth").then(() => {
//    app.mount("#app");
// });
