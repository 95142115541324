import QuizApiService from "./api/QuizApiService";
import MaterialsApiService from "@/services/api/MaterialsApiService";

export const createQuiz = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post('/quizzes', {
      title: payload.title,
      text: payload.text,
      image: payload.image,
      discipline_id: payload.disciplineId
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao adicionar questão', error);

    throw error;
  }
};

export const findQuiz = async (quizId, userToken) => {
  try {
    const response = await QuizApiService.get('/quizzes/' + quizId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar questão', error);

    throw error;
  }
};

export const updateQuiz = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post(`/quizzes/${payload.quizId}/update`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar questão', error);

    throw error;
  }
};

export const deleteQuiz = async (quizId, userToken) => {
  try {
    const response = await QuizApiService.delete('/quizzes/' + quizId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao deletar questão', error);

    throw error;
  }
};

export const getQuizzes = async (payload, userToken) => {
  try {
    let url = '/quizzes?';

    if (payload.search) {
      url += `search=${payload.search}&`;
    }

    if (payload.disciplineId) {
      url += `discipline_id=${payload.disciplineId}&`;
    }

    if (payload.materialItemId) {
      url += `material_item_id=${payload.materialItemId}&`;
    }

    if (payload.allowFeedback) {
      url += `allow_feedback=${payload.allowFeedback}&`;
    }

    if (payload.page) {
      url += `page=${payload.page}&`;
    }

    if (payload.perPage) {
      url += `per_page=${payload.perPage}&`;
    }

    const response = await QuizApiService.get(url.slice(0, -1), {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar questões:', error);
    throw error;
  }
}

export const generateQuiz = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post('/quizzes/generate', payload, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao gerar questão, tente novamente', error);

    throw error;
  }
}

export const checkFile = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post('/quizzes/file/check', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  }
  catch (error) {
    console.error('Erro ao verificar arquivo', error);

    throw error;
  }
}

export const importByFile = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post('/quizzes/file/import', payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  }
  catch (error) {
    console.error('Erro ao importar arquivo', error);

    throw error;
  }
}

export const fixQuestion = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post('/quizzes/file/fix', payload, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  }
  catch (error) {
    console.error('Erro ao corrigir questão', error);

    throw error;
  }
}

export const deleteMany = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post('/quizzes/deleteMany', payload, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  }
  catch (error) {
    console.error('Erro ao deletar questões', error);

    throw error;
  }
}

export const exportQuizzes = async (payload, userToken) => {
  try {
    const response = await QuizApiService.post(`/quizzes/disciplines/${payload.discipline_id}/export`, payload, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  }
  catch (error) {
    console.error('Erro ao exportar questões', error);

    throw error;
  }
}

export const validateQuizPassWordTry = async (payload) => {
  try {
    const response = await MaterialsApiService.post(`/material-items/${payload.quizId}/password`, payload.requestData, {
      headers: {
        Authorization: 'Bearer ' + payload.userToken
      }
    })

    return response.status
  } catch (error) {
    return error.response.status
  }
}

export default {
  createQuiz,
  findQuiz,
  updateQuiz,
  deleteQuiz,
  getQuizzes,
  generateQuiz,
  checkFile,
  importByFile,
  fixQuestion,
  deleteMany,
  exportQuizzes,
  validateQuizPassWordTry
}