<script setup>
import {computed, onBeforeUnmount, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";

const store = useStore()
const userConfigSettings = computed(() => store.getters.getConsumerSettings)
const userWidth = computed(() => store.getters.getUserWidth)
const fontMultipler = computed(() => store.getters['acessibility/fontMultiplier'])
const modalState = ref(false)
const dyslexiaIsActive = computed(() => store.getters['acessibility/dyslexiaIsActive'])

const handleDyslexiaState = () => {
  store.dispatch('acessibility/setDyslexiaState', {isActive: !dyslexiaIsActive.value})
}

const handleFontSize = (WhatToDo) => {
  if (WhatToDo === 'increment') {
    store.dispatch('acessibility/setIncrementMultiplier', {userWidth: userWidth.value})
    return
  }

  store.dispatch('acessibility/setDecrementFontMultiplier')
}

const handleAutoContrast = async (typeAction) => {
  if (typeAction === 'increment') {
    store.dispatch('acessibility/setIncrementContrastMultiplier')
    return
  }
  store.dispatch('acessibility/setDecrementContrastMultiplier')
}

const handleColorBlindnessState = () => {
  store.dispatch('acessibility/setColorBlindnessState')
}

const handleModalState = () => {
  modalState.value = !modalState.value
}

const handleNewLanguage = (lang) => {
  const iframe = document.getElementById(':2.container');
  if (iframe) {
    const doc = iframe.contentDocument || iframe.contentWindow.document;
    doc.querySelector('.VIpgJd-ZVi9od-TvD9Pc-hSRGPd').click()
  }

  if (lang !== 'pt') {
    setTimeout(() => {
      document.cookie = `googtrans=/pt/${lang}; path=/`;
      window.location.reload()
    }, 500)
  }

  store.dispatch('acessibility/setNewLanguageState')
}

const handleMaxFontMultiplerBaseInRes = () => {
  if (fontMultipler.value > 1.6 && userWidth.value < 1024) {
    store.dispatch('acessibility/resizeFontMultipler')
  }
}


watch(fontMultipler, (newValue) => {
  document.documentElement.style.fontSize = `${16 * newValue}px`;
})

watch(userWidth, () => {
  handleMaxFontMultiplerBaseInRes()
})

</script>

<template>
  <transition name="fade">
    <button
        :style="{ backgroundColor: userConfigSettings.settings.primary_color }"
        role="button"
        aria-label="Abrir Interface Acessibilidade"
        v-if="!modalState"
        @click="handleModalState"
        class="acessibility-button position-fixed border-0 rounded-3 text-white"
        translate="no"
    >
      A
    </button>
  </transition>
  <transition name="item_slide">
    <div
        v-if="modalState"
        class="acessbility-container bg-white  px-4 py-3 position-fixed rounded-3">
      <div class="d-flex justify-content-between align-items-center mb-2">
        <h3>
          Acessibilidade
        </h3>

        <button
            @click="handleModalState"
            class="close-button border-0 bg-transparent "><i class="fs-6 bi bi-x-lg"></i></button>
      </div>


      <div class="d-flex flex-wrap justify-content-center acessibility-body">
        <div class="w-auto">
          <div class="rounded-3 acessibility-option d-flex justify-content-between px-3 py-2 align-items-center">
            <button
                :style="{ backgroundColor: userConfigSettings.settings.primary_color }"
                @click="handleFontSize('decrement')"
                class="text-white border-0 rounded-1">-
            </button>
            <p class="p-0 mx-3 my-0 text-center">Ajustar Fonte</p>
            <button
                :style="{ backgroundColor: userConfigSettings.settings.primary_color }"
                @click="handleFontSize('increment')"
                class="text-white border-0 rounded-1">+
            </button>
          </div>

          <div class="mt-3 rounded-3 acessibility-option d-flex justify-content-between px-3 py-2 align-items-center">
            <button
                :style="{ backgroundColor: userConfigSettings.settings.primary_color }"
                @click="handleAutoContrast('decrement')"
                class="text-white border-0 rounded-1">-
            </button>
            <p class="text-center p-0 mx-3 my-0">Alto Contraste</p>
            <button
                :style="{ backgroundColor: userConfigSettings.settings.primary_color }"
                @click="handleAutoContrast('increment')"
                class="text-white border-0 rounded-1">+
            </button>
          </div>
        </div>

        <div>
          <div :style="{'--background-input-color': userConfigSettings.settings.primary_color }"
               class="rounded-3 mt-0 acessibility-option d-flex align-items-center px-3 py-2">
            <label
                class="switch">
              <input
                  :checked="dyslexiaIsActive"
                  @change="handleDyslexiaState"
                  type="checkbox">
              <span class="slider round"></span>
            </label>
            <p class="m-0 p-0 ms-3">Fonte para Dislexia</p>
          </div>

          <div @click="handleColorBlindnessState" class="color-blindness rounded-3 mt-3 px-3 py-2 acessibility-option d-flex  align-items-center ">
            <p class="m-0 me-2">Esquema de Cores</p>
            <i class="bi bi-droplet-half "></i>
          </div>
        </div>


        <div class="d-flex flex-column justify-content-around">
          <p class="mb-2">Linguagens</p>
          <div class="w-100 d-flex">
            <p translate="no" @click="handleNewLanguage('en')" class="languages fs-4 m-0">🇺🇸</p>
            <p translate="no" @click="handleNewLanguage('es')" class="m-0 languages fs-4 mx-2">🇪🇸</p>
            <p translate="no" @click="handleNewLanguage('pt')" class="m-0 languages fs-4">🇧🇷</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
.acessibility-body {
  gap: 10px;
  max-height: 300px;
  overflow-y: auto;
}

.languages, .color-blindness {
  cursor: pointer;
}

.bi-droplet-half {
  cursor: pointer;
  font-size: calc(12px * var(--font-scale));
}

.acessbility-container {
  right: 10px;
  top: 55%;
  z-index: 125;
}

.acessbility-container h3 {
  font-size: calc(15px * var(--font-scale));
}

.acessibility-option button {
  height: 20px;
  width: 20px;
  font-size: calc(12px);
}

.acessbility-container p {
  font-size: calc(13px * var(--font-scale));
}

.acessbility-container, .acessibility-option {
  border: 1px solid #D2D8DF;
}

.acessibility-button {
  top: 55%;
  right: 10px;
  text-align: center;
  width: 37px;
  height: 37px;
  font-size: 24px;
  z-index: 125;
}

.switch {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 21px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: var(--background-input-color);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(12px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media(max-width: 576px) {
  .acessibility-button {
    top: 40%;
  }

  .acessbility-container {
    top: 40%;
  }
}
</style>