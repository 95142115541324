import EventsService from "@/services/EventsService";

const state = {
    eventsData: []
}

const mutations = {
    setEventsData(state, payload) {
        state.eventsData = payload
    },

    editEvent(state, payload) {
        const index = state.eventsData.findIndex((
            eventInArray => eventInArray.id === payload.id
        ))

        state.eventsData[index] = payload
    },

    deleteEvent(state, payload) {
        const index = state.eventsData.findIndex((
            eventInArray => eventInArray.id === payload
        ))

        state.eventsData.splice(index, 1)
    },

    addEvent(state, payload) {
        state.eventsData.push(payload)
    }
}

const actions = {
    async getEventsData({commit, dispatch}, payload) {
        try {
            const response = await EventsService.getEvents(payload)

            commit('setEventsData', response.data)
        } catch (Error) {
            dispatch(
                'toastfy/activeErrorNotification',
                { text: Error },
                { root: true }
            );
        }
    },

    async createEvent({commit, dispatch}, payload) {
        try {
            const response  = await EventsService.createEvent(payload)
            commit('addEvent', response.data)

            dispatch(
                'toastfy/activeSuccesNotification',
                { text: "Evento criado com sucesso!" },
                { root: true }
            );
        } catch (Error) {
            dispatch(
                'toastfy/activeErrorNotification',
                { text: Error },
                { root: true }
            );
        }
    },

    async editEvent({commit, dispatch}, payload) {
        try {
            const response = await EventsService.editEvent(payload)
            commit('editEvent', response.data)
            dispatch(
                'toastfy/activeSuccesNotification',
                { text: "Evento editado com sucesso!" },
                { root: true }
            );
        } catch (Error) {
            dispatch(
                'toastfy/activeErrorNotification',
                { text: Error },
                { root: true }
            );
        }
    },

    async deleteEvent({commit, dispatch}, payload) {
        try {
            await EventsService.deleteEvent(payload)
            commit('deleteEvent', payload.eventId)

            dispatch(
                'toastfy/activeSuccesNotification',
                { text: "Evento excluido com sucesso!" },
                { root: true }
            );
        } catch (Error) {
            dispatch(
                'toastfy/activeErrorNotification',
                { text: Error },
                { root: true }
            );
        }
    }
}

const getters = {
    eventsData: (state) => state.eventsData
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}