import DisciplineTagsService from "@/services/DisciplineTagsService";

export default {
    state() {
        return {
            disciplineTag: {
                name: "",
                color: "",
                companyId: "",
                isEditable: ""
            },
            distinctTagsData: [],
            disciplineTags: []
        }
    },

    getters: {
        getDisciplineTagName: (state) => state.disciplineTag.name,
        getDisciplineTagColor: (state) => state.disciplineTag.color,
        getDisciplineTagCompanyId: (state) => state.disciplineTag.companyId,
        getDisciplineTags: (state) => state.disciplineTags,
        getDistinctTags: (state) => state.distinctTagsData
    },

    mutations: {
        setDisciplineTagData(state, data) {
            state.disciplineTag.name = data.name;
            state.disciplineTag.color = data.color;
            state.disciplineTag.companyId = data.companyId;
            state.disciplineTag.isEditable = data.is_editable;
        },
        resetDisciplineTag(state) {
            state.disciplineTag = {
                name: "",
                color: "",
                companyId: "",
            };
        },

        updateDisciplineTagData(state, data) {
            const index = state.disciplineTags.findIndex((disciplineInArray) => disciplineInArray.id === data.id)
            state.disciplineTags[index] = data
        },

        removeDisciplineTag(state, data) {
            state.disciplineTags = state.disciplineTags.filter((tagsInArray) => tagsInArray.id !== data.id)
        },
        setDisciplineTags(state, payload) {
            state.disciplineTags = (payload)
        },

        createDistinctTagData(state, data) {
          data && (state.distinctTagsData.push(data))
        },

        setDistinctTagsData(state, data) {
            state.distinctTagsData = data
        },
        includeDisciplineTag(state, data) {
            state.disciplineTags.push(data)
        },
    },

    actions: {
        async createDisciplineTag({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.createDisciplineTag(payload.disciplineTag, payload.userToken);

                commit('includeDisciplineTag', response.data);
                if (payload?.createdFromDisciplineModal) {
                    commit('createDistinctTagData', response.data)
                }

                return response.data;
            } catch (error) {
                console.error('Erro ao adicionar tag:', error);

                throw error;
            }
        },

        async findDisciplineTag({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.findDisciplineTag(payload.disciplineTagId, payload.userToken);

                commit('setDisciplineTagData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar tag:', error);

                throw new Error('Não foi possível consultar tag. Por favor, tente novamente.');
            }
        },

        async updateDisciplineTag({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.updateDisciplineTag(payload.disciplineTag, payload.userToken);

                commit('updateDisciplineTagData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao atualizar tag:', error);

                throw new Error('Não foi possível atualizar a tag. Por favor, tente novamente.');
            }
        },

        async deleteDisciplineTag({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.deleteDisciplineTag(payload.tag.id, payload.userToken);

                commit('removeDisciplineTag', payload.tag);

                return response.data;
            } catch (error) {
                console.error('Erro ao deletar tag:', error);

                throw new Error('Não foi possível deletar a tag. Por favor, tente novamente.');
            }
        },

        async getDisciplineTags({ commit, dispatch }, payload) {
            try {
                const response = await DisciplineTagsService.getDisciplineTags(payload.userToken);
                commit('setDisciplineTags', response.data);

                return response.data;
            } catch (error) {
                dispatch(
                    'toastfy/activeErrorNotification',
                    { text: error },
                    { root: true }
                );
            }
        },

        async getDistinctTags({ commit }, payload) {
            try {
                const response = await DisciplineTagsService.getDistinctTags(payload.data, payload.userToken);

                await commit('setDistinctTagsData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao consultar tags:', error);

                throw new Error('Não foi possível consultar as tags. Por favor, tente novamente.');
            }
        },
    }
}
