import UsersService from '@/services/UsersService';

export default {
    state() {
        return {
            user: {
                id: "",
                name: "",
                type: "",
                email: "",
                companies: "",
            },

            newUser: {
                id: "",
                name: "",
                type: "",
                email: "",
                companies: "",
            },

            usersData: [],

            status: "",
        }
    },

    getters: {
        getUserId: (state) => state.user.id,
        getUserName: (state) => state.user.name,
        getUserType: (state) => state.user.type,
        getUserEmail: (state) => state.user.email,
        getUserCompanies: (state) => state.user.companies,
        getUsersData: (state) => state.usersData,
    },

    mutations: {
        setUserId(state, id) {
            state.user.id = id;
        },
        setUserName(state, name) {
            state.user.name = name;
        },
        setUserType(state, type) {
            state.user.type = type;
        },
        setUserEmail(state, email) {
            state.user.email = email;
        },
        setUserCompanies(state, companies) {
            state.user.companies = companies;
        },
        setNewUserData(state, data) {
            state.newUser.id = data.id;
            state.newUser.name = data.name;
            state.newUser.type = data.type;
            state.newUser.email = data.email;
            state.newUser.companies = data.companies;
        },

        setUserData(state, data) {
            state.user.id = data.id;
            state.user.name = data.name;
            state.user.type = data.type;
            state.user.email = data.email;
            state.user.companies = data.companies;
        },

        clearNewUserData(state) {
            state.newUser.id = "";
            state.newUser.name = "";
            state.newUser.type = "";
            state.newUser.email = "";
            state.newUser.companies = "";
        },

        setUsersData(state, data) {
            state.usersData = data
        },

        setStatus(state, status) {
            state.status = status;
        },
    },

    actions: {
        setUserCompanies({commit}, data) {
            commit("setUserCompanies", data.companies);
        },

        setUserDataAndCompanies({commit}, data) {
            commit("setUserId", data.id);
            commit("setUserName", data.name);
            commit("setUserType", data.type);
            commit("setUserEmail", data.email);
            commit("setUserCompanies", data.companies);
        },

        async findUserCompanies({commit}, {userId, userToken}) {
            try {
                const response = await UsersService.findUser(userId, userToken);

                const companies = response.data.companies;

                commit('setUserCompanies', companies);

                return {companies};
            } catch (error) {
                console.error('Erro ao buscar informações do usuário:', error);
                throw error;
            }
        },

        async findUser({commit}, {userId, userToken}) {
            try {
                const response = await UsersService.findUser(userId, userToken);

                commit('setUserData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao buscar informações do usuário:', error);
                throw error;
            }
        },

        async createUser({commit}, payload) {
            try {
                const response = await UsersService.createUser(payload.user, payload.userToken);

                commit('setNewUserData', response.data);

                return response;
            } catch (error) {
                console.error('Erro ao criar usuário:', error);
                throw error;
            }
        },

        async updateUser({commit}, payload) {
            try {
                const response = await UsersService.updateUser(payload.user, payload.userToken);

                commit('setNewUserData', response.data);

                return response;
            } catch (error) {
                console.error('Erro ao atualizar usuário:', error);
                throw error;
            }
        },

        async deleteUser({commit}, payload) {
            try {
                const response = await UsersService.deleteUser(payload.userId, payload.userToken);

                commit('clearNewUserData');

                return response;
            } catch (error) {
                console.error('Erro ao atualizar usuário:', error);
                throw error;
            }
        },

        async getUsers({commit}, payload) {
            try {
                console.log(payload.userToken)
                const response = await UsersService.getUsers(payload.data, payload.userToken);
                commit('setUsersData', response.data);

                return response.data;
            } catch (error) {
                console.error('Erro ao procurar usuários:', error);
                throw error;
            }
        },

        async changePassword({commit}, payload) {
            try {
                const response = await UsersService.changePassword(payload.data, payload.userToken);

                commit('setStatus', 'Senha alterada com sucesso!');

                return response.data;
            } catch (error) {
                console.error('Erro ao alterar senha:', error);
                throw error;
            }
        },

        /* eslint-disable no-unused-vars */
        async pingUser({commit} ,payload) {

            try {
                await UsersService.pingUser(payload.userToken);
            } catch (error) {
                console.log(error);
                throw error;
            }
        }
        /* eslint-enable no-unused-vars */
    }
}
