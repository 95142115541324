import tinycolor from 'tinycolor2';
const getPrimaryLighter = (color) => {
    if (color === '#1A5276' || color === '#23761a') {
        return tinycolor(color).lighten(65).toString();
    }
    return tinycolor(color).lighten(45).toString();
};
export const setApplicationColors = (settings) => {
    const primaryLight = tinycolor(settings.primary_color).darken(1).toString();
    const primaryLighter = getPrimaryLighter(settings.primary_color);
    const primaryMedium = tinycolor(settings.primary_color).lighten(1).toString();
    const primaryDark = tinycolor(settings.primary_color).lighten(10).toString();
    const primaryDarker = tinycolor(settings.primary_color).darken(40).toString();
    const secondaryLight = tinycolor(settings.secondary_color).lighten(20).toString();
    const secondaryLighter = tinycolor(settings.secondary_color).lighten(40).toString();
    const secondaryDark = tinycolor(settings.secondary_color).lighten(10).toString();
    const secondaryDarker = tinycolor(settings.secondary_color).darken(40).toString();
    const root = document.documentElement;
    root.style.setProperty('--blue-0', settings.primary_color);
    root.style.setProperty('--blue-1', primaryDark);
    root.style.setProperty('--blue-2', primaryLight);
    root.style.setProperty('--blue-3', primaryDarker);
    root.style.setProperty('--blue-4', primaryLighter);
    root.style.setProperty('--blue-5', primaryMedium);
    root.style.setProperty('--orange-0', settings.secondary_color);
    root.style.setProperty('--orange-1', secondaryDark);
    root.style.setProperty('--orange-2', secondaryLight);
    root.style.setProperty('--orange-3', secondaryDarker);
    root.style.setProperty('--orange-4', secondaryLighter);
};
