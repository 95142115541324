import {toast} from "vue3-toastify";

const state = {
    customToastState: false
}

const getters = {
    customToastState: (state) => state.customToastState
}

const mutations = {
    changeCustomToastState(state, customState) {
        state.customToastState = customState
    }
}

const actions = {
    changeCustomToastState({commit}, payload) {
        commit('changeCustomToastState', payload)
    },

    activeErrorNotification ({commit}, payload) {
        toast.error(payload.text, {
            position: 'top-right',
            theme: 'colored'
        })
    },
    activeSuccesNotification ({commit}, payload) {
        toast.success(payload.text, {
            position: 'top-right',
            theme: 'colored'
        })
    },

    activeInfoNotification ({commit}, payload) {
        toast.info(payload.text, {
            position: 'top-right',
            theme: 'colored'
        })
    },
}

export default  {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}