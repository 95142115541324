const state = {
    modalState: false,
    modalName: null,
    modalWrapperDiv: null,
    formModal: false,
    userEnteredSomething: false
};
const getters = {
    getUserEnteredSomething: (state) => state.userEnteredSomething,
    getFormModalState: (state) => state.formModal,
    getModalName: (state) => state.modalName,
    getModalState: (state) => state.modalState,
    getModalWrapperDiv: (state) => state.modalWrapperDiv
};
const mutations = {
    setUserEnteredSomething(state, data) {
        state.userEnteredSomething = data;
    },
    setModalData(state, data) {
        state.modalState = !!data;
        state.formModal = !!data?.formModal;
        state.modalName = data?.modalName || null;
        state.modalState ?
            state.modalWrapperDiv = data?.modalWrapperDiv || '.modal-content'
            : state.modalWrapperDiv = null;
    }
};
const actions = {
    setModalData({ commit }, data) {
        commit('setModalData', data);
    },
    clearUserEnteredSomething({ commit }) {
        commit('setUserEnteredSomething', false);
    },
    setUserEnteredSomething({ commit }) {
        commit('setUserEnteredSomething', true);
    },
    clearModalData({ commit }) {
        commit('setUserEnteredSomething', false);
        commit('setModalData');
    }
};
export default {
    state,
    getters,
    actions,
    mutations
};
