import { defineComponent as _defineComponent } from 'vue';
import { createElementVNode as _createElementVNode, unref as _unref, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, resolveDirective as _resolveDirective, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "modalBackground modal d-block" };
const _hoisted_2 = {
    class: "modal-dialog modal-container modal-lg modal-dialog-centered",
    role: "document"
};
const _hoisted_3 = { class: "modal-content p-sm-5 p-" };
const _hoisted_4 = { class: "modal-body" };
const _hoisted_5 = { class: "w-100 d-lg-flex input-container" };
const _hoisted_6 = { class: "custom-width-input" };
const _hoisted_7 = { class: "custom-width-input" };
const _hoisted_8 = { class: "d-flex justify-content-end mt-3" };
import * as yup from 'yup';
import { useForm } from "vee-validate";
import { computed } from "vue";
import { useStore } from "vuex";
export default /*@__PURE__*/ _defineComponent({
    __name: 'WarmUpNewFeatureModal',
    setup(__props) {
        const store = useStore();
        const userToken = computed(() => store.getters.getToken);
        const featureToWarmUp = computed(() => store.getters.warmUpFeatureGetter);
        const validationSchema = yup.object({
            userName: yup.string()
                .max(255, "O campo Nome atingiu seu tamanho máximo")
                .required("Preencha o campo Nome"),
            companyName: yup.string()
                .max(255, "O campo Empresa atingiu seu tamanho máximo")
                .required("Preencha o campo Empresa"),
            companyEmail: yup.string()
                .max(255, "O campo Email atingiu seu tamanho máximo")
                .required("Preencha o campo Email")
                .email("Email Invalido")
                .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, "Email inválido, domínio incompleto"),
            companyNumber: yup.string()
                .max(11, "O campo WhatsApp atingiu seu tamanho máximo")
                .required("Preencha o campo WhatsApp")
                .min(11, "Preencha o campo WhatsApp no seguinte formato (DDD) 9####-####"),
            additionalComment: yup.string()
                .max(255, "O campo Comentário atingiu seu tamanho máximo")
                .optional()
        });
        const { defineField, validate, setValues } = useForm({
            validationSchema
        });
        const [userName] = defineField('userName');
        const [companyName] = defineField('companyName');
        const [companyEmail] = defineField('companyEmail');
        const [companyNumber] = defineField('companyNumber');
        const [additionalComment] = defineField('additionalComment');
        const formatNumber = (event) => {
            const number = event.target.value.replace(/\D/g, "");
            setValues({
                companyNumber: number
            });
        };
        const validateFormData = async () => {
            const isValid = await validate();
            if (isValid.valid)
                return true;
            const errorsInArray = Object.values(isValid.errors);
            await store.dispatch('toastfy/activeErrorNotification', { text: errorsInArray[0] });
        };
        const closeModal = () => {
            store.dispatch('clearModalData');
        };
        const submitData = async () => {
            if (!await validateFormData())
                return;
            const payload = {
                requestData: {
                    name: userName.value,
                    company: companyName.value,
                    contact: companyNumber.value,
                    email: companyEmail.value,
                    ...(additionalComment.value && { additional_comments: additionalComment.value }),
                    functionality_triggered: featureToWarmUp.value
                },
                userToken: userToken.value
            };
            await store.dispatch('registerCompanyInterestInNewFeature', payload);
            closeModal();
        };
        return (_ctx, _cache) => {
            const _directive_mask = _resolveDirective("mask");
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "modal-header border-0" }, [
                            _createElementVNode("h5", {
                                class: "modal-title",
                                id: "exampleModalLongTitle"
                            }, "Seja um dos primeiros a experimentar o futuro da nossa plataforma!")
                        ], -1)),
                        _createElementVNode("div", _hoisted_4, [
                            _cache[11] || (_cache[11] = _createElementVNode("p", null, "Estamos desenvolvendo novas funcionalidades incríveis e gostaríamos que você fizesse parte dos primeiros testes! Inscreva-se para ter acesso antecipado e ajudar a moldar o futuro da nossa plataforma.", -1)),
                            _createElementVNode("form", {
                                onSubmit: _withModifiers(submitData, ["prevent"])
                            }, [
                                _cache[8] || (_cache[8] = _createElementVNode("label", null, "Nome", -1)),
                                _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => (_isRef(userName) ? (userName).value = $event : null)),
                                    class: "name-input form-control w-100"
                                }, null, 512), [
                                    [_vModelText, _unref(userName)]
                                ]),
                                _cache[9] || (_cache[9] = _createElementVNode("label", null, "Empresa", -1)),
                                _withDirectives(_createElementVNode("input", {
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => (_isRef(companyName) ? (companyName).value = $event : null)),
                                    class: "form-control w-100"
                                }, null, 512), [
                                    [_vModelText, _unref(companyName)]
                                ]),
                                _createElementVNode("div", _hoisted_5, [
                                    _createElementVNode("div", _hoisted_6, [
                                        _cache[5] || (_cache[5] = _createElementVNode("label", null, "E-mail", -1)),
                                        _withDirectives(_createElementVNode("input", {
                                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => (_isRef(companyEmail) ? (companyEmail).value = $event : null)),
                                            class: "form-control w-100"
                                        }, null, 512), [
                                            [_vModelText, _unref(companyEmail)]
                                        ])
                                    ]),
                                    _createElementVNode("div", _hoisted_7, [
                                        _cache[6] || (_cache[6] = _createElementVNode("label", null, "WhatsApp", -1)),
                                        _withDirectives(_createElementVNode("input", {
                                            onInput: _cache[3] || (_cache[3] = event => formatNumber(event)),
                                            class: "form-control w-100"
                                        }, null, 544), [
                                            [_directive_mask, '(##) 9####-####']
                                        ])
                                    ])
                                ]),
                                _cache[10] || (_cache[10] = _createElementVNode("label", null, "Comentário (opcional)", -1)),
                                _withDirectives(_createElementVNode("textarea", {
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => (_isRef(additionalComment) ? (additionalComment).value = $event : null)),
                                    class: "form-control w-100",
                                    rows: "7"
                                }, null, 512), [
                                    [_vModelText, _unref(additionalComment)]
                                ]),
                                _createElementVNode("div", _hoisted_8, [
                                    _createElementVNode("button", {
                                        onClick: _withModifiers(closeModal, ["stop"]),
                                        type: "button",
                                        class: "close-button btn btn-secondary"
                                    }, "Fechar"),
                                    _cache[7] || (_cache[7] = _createElementVNode("button", {
                                        type: "submit",
                                        class: "submit-button ms-3 btn btn-primary"
                                    }, "Enviar", -1))
                                ])
                            ], 32)
                        ])
                    ])
                ])
            ]));
        };
    }
});
