import CompaniesService, { getCompanyByConsumer } from '@/services/CompaniesService';

export default {
  state() {
    return {
      newCompany: [],
      company: [],
      companiesData: [],
      consumerCompany: []
    }

  },

  getters: {
    getCompanyName: (state) => state.newCompany.name,
    getCompanyCollaborators: (state) => state.newCompany.collaborators,
    getCompanyResponsibleId: (state) => state.newCompany.responsibleId,
    getCompaniesData: (state) => state.companiesData,
    getCompanyData: (state) => state.company,
    getConsumerCompany: (state) => state.consumerCompany
  },

  mutations: {
    setCompanyData(state, data) {
      state.company.name = data.name;
      state.company.responsible = data.responsible;
      state.company.materialsLicense = data.materials_license;
      state.company.projectManagerLicense = data.project_manager_license;
      state.company.tutorManagerLicense = data.tutor_manager_license;
      state.company.students = data.students;
      state.company.tutors = data.tutors;
      state.company.openaiEnabled = data.openai_enabled;
      state.company.smartQuestionBank = data.smart_question_bank;
      state.company.topicGenerator = data.topic_generator;
      state.company.smartChatBot = data.smart_chat_bot;
      state.company.proMode = data.pro_mode;
    },
    setCompaniesData(state, data) {
      state.companiesData = data;
    },
    setNewCompanyName(state, name) {
      state.newCompany.name = name;
    },
    setNewCompanyCollaborators(state, collaborators) {
      state.newCompany.collaborators = collaborators;
    },
    setNewCompanyResponsibleId(state, responsibleId) {
      state.newCompany.responsibleId = responsibleId;
    },
    resetNewCompany(state) {
      state.newCompany = {
        name: "",
        collaborators: null,
        responsibleId: null,
      };
    },
    setConsumerCompany(state, data) {
      state.consumerCompany = data;
    }
  },

  actions: {
    async createCompany({ commit }, payload) {
      try {
        const response = await CompaniesService.createCompany(payload.company, payload.userToken);

        commit('resetNewCompany');

        return response.data;
      } catch (error) {
        console.error('Erro ao adicionar empresa:', error);

        throw error;
      }
    },

    async findCompany({ commit }, payload) {
      try {
        const response = await CompaniesService.findCompany(payload.companyId, payload.userToken);
        commit('setCompanyData', response.data);

        return response.data;
      } catch (error) {
        console.error('Erro ao consultar empresa:', error);

        throw new Error('Não foi possível consultar empresa. Por favor, tente novamente.');
      }
    },

    async updateCompany({ commit }, payload) {
      try {
        const response = await CompaniesService.updateCompany(payload.company, payload.userToken);

        commit('setCompanyData', response.data);

        return response.data;
      } catch (error) {
        console.error('Erro ao atualizar empresa:', error);

        throw error;
      }
    },

    async deleteCompany({ commit }, payload) {
      try {
        const response = await CompaniesService.deleteCompany(payload.companyId, payload.userToken);

        commit('resetNewCompany');

        return response.data;
      } catch (error) {
        console.error('Erro ao deletar empresa:', error);

        throw new Error('Não foi possível deletar a empresa. Por favor, tente novamente.');
      }
    },

    async getCompanies({ commit }, payload) {
      try {
        const response = await CompaniesService.getCompanies(payload.data, payload.userToken);

        commit('setCompaniesData', response.data);

        return response.data;
      } catch (error) {
        console.error('Erro ao consultar empresas:', error);

        throw new Error('Não foi possível consultar as empresas. Por favor, tente novamente.');
      }
    },

    async restoreCompany({ commit }, payload) {
      try {
        const response = await CompaniesService.restoreCompany(payload.companyId, payload.userToken);

        commit('resetNewCompany');

        return response.data;
      } catch (error) {
        console.error('Erro ao restaurar empresa:', error);

        throw new Error('Não foi possível restaurar as empresa. Por favor, tente novamente.');
      }
    },

    async getCompanyByConsumer({ commit }, payload) {
      try {
        const response = await getCompanyByConsumer(payload.domain, payload.userToken);

        commit('setConsumerCompany', response.data);

        localStorage.setItem('company', response.data);
        return response.data;
      } catch (error) {
        console.error('Erro ao consultar empresa:', error);

        throw new Error('Não foi possível consultar empresa. Por favor, tente novamente.');
      }
    }
  }
}
