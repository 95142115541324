const state = {
    selectState: false,
    selectName: null,
    selectWrapperDiv: null
};
const getters = {
    getSelectState: (state) => state.selectState,
    getSelectName: (state) => state.selectName,
    getSelectWrapper: (state) => state.selectWrapperDiv
};
const mutations = {
    setSelectData(state, data) {
        state.selectState = !!data;
        state.selectName = data ? data.selectName : null;
        state.selectState ?
            state.selectWrapperDiv = data?.selectWrapperDiv || '.select-content'
            : state.selectWrapperDiv = null;
    }
};
const actions = {
    setSelectData({ commit }, data) {
        commit('setSelectData', data);
    },
    clearSelectData({ commit }) {
        commit('setSelectData');
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};
