import MaterialsApiService from "./api/MaterialsApiService";

export const createMaterialItem = async (payload, userToken) => {
  try {
    const response = await MaterialsApiService.post('/material-items', {
      title: payload.title,
      type: payload.type,
      description: payload.description,
      file: payload.file,
      iframe_code: payload.iframeCode,
      color: payload.color,
      image: payload.image,
      audio: payload.audio,
      quizzes_ids: payload.quizzesIds,
      material_id: payload.materialId,
      scorm: payload.scorm
    }, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao adicionar item', error);

    throw error;
  }
};

export const findMaterialItem = async (materialItemId, userToken) => {
  try {
    const response = await MaterialsApiService.get('/material-items/' + materialItemId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar item', error);

    throw error;
  }
};

export const updateMaterialItem = async (payload, userToken) => {
  try {
    const response = await MaterialsApiService.post(`/material-items/${payload.materialItemId}/update`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar item', error);

    throw error;
  }
};

export const deleteMaterialItem = async (materialItemId, userToken) => {
  try {
    const response = await MaterialsApiService.delete('/material-items/' + materialItemId, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao deletar item', error);

    throw error;
  }
};

export const getMaterialItems = async (payload, userToken) => {
  try {
    let url = '/material-items?';

    if (payload.search) {
      url += `search=${payload.search}&`;
    }

    if (payload.materialId) {
      url += `material_id=${payload.materialId}&`;
    }

    const response = await MaterialsApiService.get(url.slice(0, -1), {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar items:', error);
    throw error;
  }
}

export const duplicateMaterialItem = async (materialItemId, userToken) => {
  try {
    const response = await MaterialsApiService.post(`/material-items/${materialItemId}/duplicate`, null, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao duplicar item', error);

    throw error;
  }
}

export const removeQuiz = async (payload, userToken) => {
  try {
    const response = await MaterialsApiService.post(`/material-items/${payload.materialItemId}/remove-quiz`, payload, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao remover questão', error);

    throw error;
  }
}

export const getAttempts = async (payload, userToken) => {
  try {
    let url = `/material-items/${payload.materialItemId}/attempts?`;

    if (payload.name) {
      url += `name=${payload.name}&`;
    }

    if (payload.userId) {
      url += `user_id=${payload.userId}&`;
    }

    if (payload.status) {
      url += `status=${payload.status}&`;
    }

    if (payload.role) {
      url += `role=${payload.role}&`;
    }

    const response = await MaterialsApiService.get(url.slice(0, -1), {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao consultar tentativas:', error);
    throw error;
  }
}

export const updateIndex = async (payload, userToken) => {
  try {
    const response = await MaterialsApiService.post(`/material-items/${payload.materialItemId}/index`, payload, {
      headers: {
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao atualizar índice', error);

    throw error;
  }
}

export const uploadMaterialItemFile = async (payload, userToken) => {
  try {
    const response = await MaterialsApiService.post(`/material-items/upload`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + userToken
      }
    });

    return response.data;
  } catch (error) {
    console.error('Erro ao fazer upload de arquivos', error);

    throw error;
  }
}

export const uploadItemsByZip = async (payload) => {
  try {
    const response = await MaterialsApiService.post('/materials/file/import', payload.filesData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + payload.userToken
      }
    })

    return response.data
  } catch (error) {
    throw error.response.data
  }
}

export default {
  createMaterialItem,
  findMaterialItem,
  updateMaterialItem,
  deleteMaterialItem,
  getMaterialItems,
  duplicateMaterialItem,
  removeQuiz,
  getAttempts,
  updateIndex,
  uploadMaterialItemFile,
  uploadItemsByZip
}