import AuthService from "./api/AuthService";

export const getConsumers = async (payload, userToken) => {
    try {
        let url = '/consumers?';

        if (payload.search) {
            url += `search=${payload.search}&`;
        }

        if (payload.companyId) {
            url += `company_id=${payload.companyId}&`;
        }

        if (payload.distinctResposibleId) {
            url += `distinct_responsible_id=${payload.distinctResposibleId}&`;
        }

        const response = await AuthService.get(url.slice(0, -1), {
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao buscar consumidores', error);

        throw error;
    }
}


export const createConsumer = async (payload, userToken) => {
    try {
        const response = await AuthService.post('/consumers', payload, {
            headers: {
                'Authorization': 'Bearer ' + userToken
            }
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao adicionar consumidor', error);

        throw error;
    }
}

export const getStudentCSVMetrics = async (payload) => {
    try {
        const response = await AuthService.post('/sessions/export/csv', payload.usersData, {
            headers: {
                'Authorization': 'Bearer ' + payload.userToken
            }
        })
        return response.data
    } catch {
        throw new Error("Erro ao exportar relatório")
    }
}

export const getSessionData = async (payload) => {
    let url = '/sessions'

    if (payload.userId) {
        url += `/${payload.userId}`
    }

    if (payload.type === 'Students Rank' || payload.type === 'Students Data For Tutors') {
        url += '?type=student&'
    }

    if (payload.tutorId) {
        url += `tutor_id=${payload.tutorId}&`
    }

    if (payload.searchUserName) {
        url += `name=${payload.searchUserName}&`
    }

    if (payload.page) {
        url += `page=${payload.page}&`
    }

    if (payload.perPage) {
        url += `per_page=${payload.perPage}&`
    }
    try {
        const response = await AuthService.get(url, {
            headers: {
                'Authorization': 'Bearer ' + payload.userToken
            }
        })

        if (!response.data.data) {
            return response
        }

        return response.data
    } catch {
        throw new Error("Erro ao requisitar dados da sessão")
    }
}

export const getConsumerBadges = async (payload) => {
    try {
        const response = await AuthService.get(`/badges/users/${payload.userId}`, {
            headers: {
                'Authorization': 'Bearer ' + payload.userToken
            }
        })

        return response.data
    } catch {
        throw new Error("Erro ao requisitar Badges")
    }
}

export const getConsumerDashBoard = async (payload) => {
    try {
        const response = await AuthService.get(`/sessions/${payload.userId}/dashboard?filter=${payload.timeRange}`, {
            headers: {
                'Authorization': 'Bearer ' + payload.userToken
            }
        })

        return response.data
    } catch {
        throw new Error("Erro ao requisitar DashBoard")
    }
}

export const getTutorMetrics = async (payload) => {
    try {
        const response = await AuthService.get('/tutors/metrics', {
            headers: {
                'Authorization': 'Bearer ' + payload.userToken
            }
        })
        return response.data
    } catch {
        throw new Error("Erro ao requisitar métricas do Tutor")
    }
}

export const setTutorWorkLoad = async (payload) => {
    try {
        const response = await AuthService.post('/tutor-settings', payload.tutorData, {
            headers: {
                'Authorization': 'Bearer ' + payload.userToken
            }
        })

        return response.data
    } catch {
        throw new Error("Erro ao Atualizar Carga Horária!")
    }
}

export default {
    getConsumers,
    createConsumer,
    getSessionData,
    getConsumerBadges,
    getConsumerDashBoard,
    getTutorMetrics,
    setTutorWorkLoad,
    getStudentCSVMetrics
}