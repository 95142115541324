import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import {baseURL} from "@/services/api/AuthService";

let echoInstance = null

const echoInstanceParams = () => echoInstance

const initializeEchoInstance = (userToken) => {
    if (echoInstance) return
    const authURL = `${baseURL}/broadcasting/auth`

    const getWsHotConnect = () => {
        if (process?.env?.VUE_APP !== 'localhost') {
            const subdomain = window.location.host.split('.')[0]
            return `${subdomain}.${process.env.VUE_APP_ARANDU_API_URL}`
        }
        return 'localhost'
    }

    const wsHost = getWsHotConnect()

    echoInstance = new Echo({
        broadcaster: 'reverb',
        key: process.env.VUE_APP_REVERB_KEY,
        wsHost: wsHost,
        wsPort: process?.env?.VUE_APP === 'localhost' ? 6001 : 80,
        wssPort: process?.env?.VUE_APP === 'localhost' ? 6001 : 443,
        forceTLS: window.location.protocol === 'https',
        path: '/app',
        enabledTransports: ['ws', 'wss'],
        authEndpoint: authURL,
        auth: {
            headers: {
                Authorization: 'Bearer ' + userToken,
            },
        }
    });
}

const leavePrivateChannel = (channelName) => {
    if (!echoInstance) return
    echoInstance.leave(`private-${channelName}`)
}

export {
    initializeEchoInstance,
    leavePrivateChannel,
    echoInstanceParams,
};
