import axios from "axios";
import store from "@/store";

const subdomain = window.location.host.split('.')[0]

export const baseUrl = process.env.VUE_APP_APPLICATION_SECURITY + subdomain + '.' + process.env.VUE_APP_ARANDU_MATERIALS_URL

const MaterialsApiService = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-type": "application/json",
    Accept: "application/json",
  },
});

MaterialsApiService.interceptors.request.use(
  (config) => {
    const token = store.state.login.authToken;

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  
  (error) => {
    return Promise.reject(error);
  }
);

export default MaterialsApiService;


