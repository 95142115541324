import CompaniesService from '../services/CompaniesService.js';
const state = {
    warmUpFeature: ''
};
const getters = {
    warmUpFeatureGetter: (state) => state.warmUpFeature
};
const mutations = {
    setWarmUpData(state, warmUpFeature) {
        if (!warmUpFeature) {
            state.warmUpFeature = '';
            return;
        }
        state.warmUpFeature = warmUpFeature;
    }
};
const actions = {
    setWarmUpData({ commit }, warmUpFeature) {
        commit('setWarmUpData', warmUpFeature);
    },
    async registerCompanyInterestInNewFeature({ commit, dispatch }, payload) {
        try {
            await CompaniesService.registerCompanyInterestInNewFeature(payload);
            dispatch('toastfy/activeSuccesNotification', { text: "Interesse registrado, em breve entraremos em contato!" }, { root: true });
        }
        catch (Error) {
            dispatch('toastfy/activeErrorNotification', { text: Error }, { root: true });
        }
    }
};
export default {
    state,
    getters,
    mutations,
    actions
};
