import {ref} from "vue";

const loadingModule = {
    state: {
        consumerSettings: {},
        userWidth: window.innerWidth,
        loadignState: false,
        loadingView: ''
    },
    mutations: {
        setConsumerSettings(state, data) {
            state.consumerSettings = {...data, original_primary_color: data.settings.primary_color, original_secondary_color: data.settings.secondary_color};
        },

        setLoadingState(state, payload) {
            state.loadignState = payload.loadignState
            state.loadignState ? state.loadingView = payload.loadingView : state.loadingView = ''
        },

        setUserWidth(state, payload) {
            state.userWidth = payload
        },

        editConsumerColors(state, payload) {
           state.consumerSettings.settings.primary_color = payload.primary_color
           state.consumerSettings.settings.secondary_color = payload.secondary_color
        } ,
    },
    actions: {
        editConsumerColors({commit}, data) {
          commit('editConsumerColors', data)
        },

        setConsumerSettings({ commit }, data) {
            const moduleData = ref(data)
            const vCalendarCollor = ref('')
            switch (moduleData.value.settings.primary_color) {
                case '#FF4500':
                    vCalendarCollor.value = 'orange'
                    break
                case '#EE751C':
                    vCalendarCollor.value = 'orange'
                    break
                case '#1A5276':
                    vCalendarCollor.value = 'blue'
                    break
                case '#347dbd':
                    vCalendarCollor.value = 'blue'
                    break
                case '#23761a':
                    vCalendarCollor.value = 'green'
                    break
                case '#34bd6b':
                    vCalendarCollor.value = 'green'
                    break
                case '#aa00ff':
                    vCalendarCollor.value = 'purple'
                    break
                case '#6F42C1':
                    vCalendarCollor.value = 'indigo'
                    break
                case '#ff0026':
                    vCalendarCollor.value = 'red'
                    break
                case '#DC3545':
                    vCalendarCollor.value = 'red'
                    break
                case '#FFC107':
                    vCalendarCollor.value = 'yellow'
                    break
                case '#6C757D': 
                    vCalendarCollor.value = 'gray'
                    break
            }

            moduleData.value.settings = {
                ...moduleData.value.settings,
                vCallendarColor: vCalendarCollor.value
            }

            commit('setConsumerSettings', data);
        },
        setLoadingState({commit}, payload) {
            commit('setLoadingState', payload)
        },

        setUserWidth({commit}, payload) {
            commit('setUserWidth', payload)
        }
    },
    getters: {
        getUserWidth: (state) => state.userWidth,
        getConsumerSettings: state => state.consumerSettings,
        loadingState: (state) => state.loadignState,
        loadingView: (state) => state.loadingView
    }
};

export default loadingModule;