import ConsumersService from "@/services/ConsumersService";
import authService from "@/services/api/AuthService";

export default {
    state() {
        return {
            newConsumer: [],
            consumer: [],
            consumersData: [],
            status: null,
            sessionData: [],
            studentsRank: [],
            consumerBadges: [],
            studentDashBoardData: [],
            tutorDashBoardData: [],
            tutorMetrics: [],
            studentsMetricsForTutors: [],
            studentsCSVLink: ''
        }
    },

    getters: {
        getStudentsCSVLink: (state) => state.studentsCSVLink,
        getTutorDashBoardData: (state) => state.tutorDashBoardData,
        getStudentsMetricsForTutors: (state) => state.studentsMetricsForTutors,
        getStudentDashBoardData: (state) => state.studentDashBoardData,
        getSessionData: (state) => state.sessionData,
        getStudentsRank: (state) => state.studentsRank,
        getConsumerBadges: (state) => state.consumerBadges,
        getTutorMetrics: (state) => state.tutorMetrics,
        getNewConsumerName: (state) => state.newConsumer,
        getConsumerData: (state) => state.consumer,
        getConsumersData: (state) => state.consumersData
    },

    mutations: {
        setStudentsCSVLink(state, data) {
          data && (state.studentsCSVLink = data)
            console.log("Data: ", data)
            console.log("State: ", state.studentsCSVLink)
        },

        setTutorDashBoardData(state, payload) {
          payload && (state.tutorDashBoardData = payload)
        },

        setConsumerBadges(state, payload) {
            state.consumerBadges = payload
        },

        setStudentsMetricsForTutors(state, payload) {
            payload && (state.studentsMetricsForTutors = payload)
        },

        setTutorMetrics(state, response) {
          if (response) {
              state.tutorMetrics = response
          }
        },

        updateTutorWorkLoad(state, data) {
            if (data) {
                state.tutorMetrics.tutor.weekly_hours = data
            }
        },

        setConsumerData(state, data) {
            state.consumer = data;
        },
        setConsumersData(state, data) {
            state.consumersData = data;
        },
        setStudentsRank(state, data) {
            const rankOrder = data.sort((a, b) => a.student_rank - b.student_rank)
            state.studentsRank = rankOrder
        },

        setNewConsumer(state, consumer) {
            state.newConsumer = consumer;
        },
        setStatus(state, status) {
            state.status = status;
        },
        setSessionData(state, payload){
            state.sessionData = payload
        },

        setStudentDashBoardData(state, payload) {
            state.studentDashBoardData = payload
        }
    },

    actions: {
        async getConsumers({ commit }, payload) {
            try {
                const response = await ConsumersService.getConsumers(payload.data, payload.userToken);

                commit('setConsumersData', response.data);
            } catch (error) {
                console.error('Erro ao buscar consumidores', error);

                commit('setStatus', 'Erro ao buscar consumidores');
            }
        },

        async createConsumer({ commit }, payload) {
            try {
                const response = await ConsumersService.createConsumer(payload.consumer, payload.userToken);

                commit('setNewConsumerData', response);

                commit('setStatus', 'Consumidor adicionado com sucesso');
            } catch (error) {
                console.error('Erro ao adicionar consumidor', error);

                commit('setStatus', 'Erro ao adicionar consumidor');
            }
        },

        async getSessionData({commit, dispatch}, payload) {
            try {
                const response = await ConsumersService.getSessionData(payload)

                switch (payload.type) {
                    case 'Specific User':
                        commit('setSessionData', response.data)
                        break;
                    case 'Students Rank':
                        commit('setStudentsRank', response.data)
                        break;
                    case 'Students Data For Tutors':
                        commit('setStudentsMetricsForTutors', response.data)
                        break;
                }
            } catch (error) {
                dispatch('toastfy/activeErrorNotification', {text: error}, {root: true});
            }
        },

        async getDashBoardData({commit, dispatch}, payload) {
            try {
                const response = await ConsumersService.getConsumerDashBoard(payload)
                if (payload.typeAction === 'tutorDashBoardData') {
                    commit('setTutorDashBoardData', response.data)
                    return
                }

                commit('setStudentDashBoardData', response.data)
            } catch (error) {
                dispatch('toastfy/activeErrorNotification', {text: error}, {root: true});
            }
        },

        async getConsumerBadges({commit, dispatch}, payload) {
            try {
                const response = await ConsumersService.getConsumerBadges(payload)
                commit('setConsumerBadges', response.data)
            } catch (error) {
                dispatch('toastfy/activeErrorNotification', {text: error}, {root: true});
            }
        },

        async getTutorMetrics({commit, dispatch}, payload) {
            try {
                const response = await ConsumersService.getTutorMetrics(payload)
                commit('setTutorMetrics', response)
            } catch (Error) {
                dispatch('toastfy/activeErrorNotification', {text: Error}, {root: true});
            }
        },

        async setTutorWorkLoad({commit, dispatch}, payload) {
            try {
                await ConsumersService.setTutorWorkLoad(payload)
                commit('updateTutorWorkLoad', payload.tutorData.weekly_hours)
                dispatch('toastfy/activeSuccesNotification', {text: "Carga horária alterada com sucesso!"}, {root: true});
            } catch (Error) {
                dispatch('toastfy/activeErrorNotification', {text: Error}, {root: true});
            }
        },

        async getStudentsMetricsCSV({commit, dispatch}, payload) {
            try {
                dispatch('toastfy/activeSuccesNotification', {text: "Gerando Relatório!"}, {root: true});
                const response = await ConsumersService.getStudentCSVMetrics(payload)
                commit('setStudentsCSVLink', response.url)
                dispatch('toastfy/activeSuccesNotification', {text: "Relatório Exportado!"}, {root: true});
            } catch (Error) {
                console.log("Erro Ativado na Store", Error)
                dispatch('toastfy/activeErrorNotification', {text: Error}, {root: true});
            }
        }
    }
}